import { Button, Input, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import classes from './BlogEntryForm.module.css'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { AddImagesToBlogEntryThunk, CreateBlogEntryThunk, DeleteBlogEntryImagesThunk, EditBlogEntryThunk, GetBlogEntryByIdThunk, selectCurrentBlogEntry, setCurrentBlogEntry } from '../../../store/blogReducer'
import { BlogFormImageType, NewBlogEntryType } from '../../../types/blogTypes'
import { getImageUrl } from '../../../helpers/files_helper'
import { selectUserData } from '../../../store/userReducer'
import UploadBlogImages from './UploadBlogImages/UploadBlogImages';
import { slugify } from '../../../helpers/funcHelper';

const BlogEntryForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const currentBlogEntry = useAppSelector(selectCurrentBlogEntry)
  const userData = useAppSelector(selectUserData)
  const forbiddenTitleSymbolsRegex = /_/

  const [formValues, setFormValues] = useState<FormDataType>({
    title: '',
    content: '',
    images: []
  })
  const [editingEntryId, setEditingEntryId] = useState(0)
  const [validationError, setValidationError] = useState('')
  const [isEditingDataUpdated, setIsEditingDataUpdated] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (isEditing) {
      // pathname example: /blog/edit/1111
      const id = location.pathname.split('edit/')[1]
      setEditingEntryId(+id)
      dispatch(GetBlogEntryByIdThunk(+id))
    }
    return () => {dispatch(setCurrentBlogEntry(null))}
  }, [isEditing, dispatch, location])

  useEffect(() => {
    if (isEditing && currentBlogEntry?.id) {
      setFormValues({
        title: currentBlogEntry.title,
        content: currentBlogEntry.content,
        images: currentBlogEntry?.blog_images?.map(image => ({
          comment: image?.comment,
          alt: image?.image_html,
          preview: {uid: image?.image_url!, url: image?.image_url!},
          is_cover: image?.is_cover
        }))
      })
      setIsEditingDataUpdated(true)
    }
  }, [isEditing, currentBlogEntry])

  const onTitleChange = (value: string) => {
    if (forbiddenTitleSymbolsRegex.test(value)) {
      return 
    } else {
      setFormValues({...formValues, title: value})
    }
  }

  const addImage = async(file:any) => {
    const imageUrl = await getImageUrl(file)
    setFormValues({
      ...formValues,
      images: [
        ...formValues.images || [],
        {
          file,
          preview: {uid: file.uid, url: imageUrl},
          comment: '',
          alt: '',
          is_cover: !formValues.images || formValues.images?.length === 0,
          isNew: isEditing,
          blog_entry_id: isEditing ? currentBlogEntry?.id : undefined
        }
      ]
    })
  }

  const removeImage = async(uid: string, isNew: boolean) => {
    const updatedImages = formValues.images.filter(img => img.preview.uid !== uid)
    if (!!updatedImages.length && !!formValues.images.find(img => img.preview.uid === uid)?.is_cover) {
      updatedImages[0].is_cover = true
    }
    setFormValues({
      ...formValues,
      images: updatedImages,
      ...(isEditing && !isNew ? {deleteImageId: [...formValues?.deleteImageId || [], currentBlogEntry?.blog_images.find(i => i.image_url === uid)?.id!]} : {})
    })
  }

  const getValidationError: () => string = () => {
    let formError = ''
    if (!formValues?.title?.length) {
      formError = `Post title is required!`
    } else if (!formValues?.content.replace(/<\/?[^>]+(>|$)/g, '').trim()?.length) {
      formError = `Post content is required!`
    } else if (formValues.images?.some(i => !i.alt?.length)) {
      formError = 'Alternative text is required for all images!'
    }
    setValidationError(formError)
    return formError
  }

  const sendData = async(blogEntryData: NewBlogEntryType) => {
    if (isEditing) {
      const newImages = blogEntryData?.images?.filter(image => !!image.isNew)
      const getPrevImages = () => {
        const leftImages = currentBlogEntry?.blog_images?.filter(image => !blogEntryData?.deleteImageId?.includes(image.id))
        return leftImages?.map(i => {
          const imageFormData = blogEntryData?.images?.find(image => image.preview.uid === i.image_url)
          return {
            ...i,
            image_html: imageFormData?.alt!,
            is_cover: imageFormData?.is_cover!
          }
        }) || []
      }
      const resp = await Promise.all([
        dispatch(EditBlogEntryThunk({blogEntry: {...blogEntryData, blog_images: getPrevImages()}, id: editingEntryId})),
        ...(blogEntryData?.deleteImageId?.length ? [dispatch(DeleteBlogEntryImagesThunk({entryId: editingEntryId, imageIds: blogEntryData?.deleteImageId}))] : []),
        ...(newImages?.length ? [dispatch(AddImagesToBlogEntryThunk({entryId: editingEntryId, imageData: newImages}))] : []),
      ])
      return resp[0]
    } else {
      return dispatch(CreateBlogEntryThunk(blogEntryData))
    }
  }

  const handleSave = async() => {
    const error = getValidationError()
    if (error?.length) return 
    const blogEntryData: NewBlogEntryType = {
      user_id: userData?.user_id,
      title: formValues.title,
      content: formValues.content,
      is_active: true,
      images: formValues.images,
      deleteImageId: formValues?.deleteImageId,
      url: slugify(formValues.title)
    }
    setIsSaving(true)
    sendData(blogEntryData)
      .then((resp) => {
        setIsSaving(false)
        !resp?.type.includes('rejected') && navigate('/blog')
      })
  }

  if (isEditing && !Object.keys(currentBlogEntry || {}).length) {
    return <Spin className={classes.spinner} />
  }

  return (
    <>
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentBlogEntry?.title : 'Adding a new post'}
        </h1>
      </div>

      <div className={classes.formBlock}>
        <div className={classes.fieldBlock}>
          <div className={classes.label}>
            Post Title
          </div>
          <Input
            placeholder='Post title'
            onChange={(e) => onTitleChange( e.target.value)}
            value={formValues.title}
            maxLength={50}
            showCount
          />
        </div>
        <div className={classes.fieldBlock}>
          <div className={classes.label}>
            Post Content
          </div>
          <ReactQuill 
            theme='snow'
            onChange={(value) => {
              if (!isEditing || (!!isEditing && isEditingDataUpdated)) {
                setFormValues({...formValues, content: value})
              }
            }}
            value={formValues.content}
            className={classes.textEditor}
            placeholder='Enter post content here...'
          />
        </div>

        <div className={classes.fieldBlock}>
          <div className={classes.label}>
            Images
          </div>
          <UploadBlogImages
            addPhoto={addImage}
            removePhoto={(uid: string, isNew: boolean) => removeImage(uid, isNew)}
            images={formValues.images}
            updateImagesData={(images: BlogFormImageType[]) => setFormValues({...formValues, images})}
          />
        </div>

        <div className={classes.buttons}>
          {!!validationError.length &&
            <div className={classes.error}>
              {validationError}
            </div>
          }
          <Link to='/blog'>
            <Button>
              Cancel
            </Button>
          </Link>
            <Button
              type='primary'
              loading={isSaving}
              onClick={() => handleSave()}
            >
              Save
            </Button>
        </div>
      </div>
    </>
  )
}

interface FormDataType {
  title: string
  content: string
  images: BlogFormImageType[]
  deleteImageId?: number[]
}

export default BlogEntryForm
