import { Checkbox, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import classes from './EventDetailsPage.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetEventByCodeThunk, selectCurrentEvent } from '../../../store/eventReducer'
import axios from './../../../helpers/axiosHelper'
import moment from 'moment'

const EventDetailsPage = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const currentEvent = useAppSelector(selectCurrentEvent)

  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    // pathname example: /events/view/111
    const code = +location.pathname.split('/view/')[1]

    setIsDataLoading(true)
    dispatch(GetEventByCodeThunk(code))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch])

  const getBaseUrl = () => {
    if (window.location.origin?.includes('scopia-admin-dev.esverito.com')) {
      return 'https://scopia-user-dev.esverito.com'
    } else if (window.location.origin?.includes('scopia-admin-test.esverito.pl')) {
      return 'https://scopia-user-test.esverito.com'
    } else {
      return 'https://www.relavanti.com'
    }
  }

  if (isDataLoading) {
    return <Spin style={{width: '100%'}}/>
  }

  return (

    <>
      <div>
        <Breadcrumbs />
        <h1>
          {currentEvent?.name}
        </h1>
      </div>

      <div className={classes.mainInfoArea}>
        <div className={classes.formBlock}>
          <div className={classes.field}>
            <div className={classes.label}>Name Event</div>
              <div className={classes.fieldValue}>
                {currentEvent?.name}
              </div>
            {/* <Input value={currentEvent?.name}/> */}
          </div>

          <div className={classes.field}>
            <div className={classes.label}>Format</div>
              <div className={classes.fieldValue}>
                {currentEvent?.event_kind}
              </div>
            {/* <Input value={currentEvent?.event_kind}/> */}
          </div>

          <div className={classes.field}>
            {currentEvent?.event_kind === 'Location' ? (
              <>
                <div className={classes.label}>Event Location</div>
                <div className={classes.fieldValue}>
                  {currentEvent?.event_location?.name}
                </div>
                {/* <Input value={currentEvent?.event_location?.name}/> */}
              </>
            ) : (
              <>
                <div className={classes.label}>Event Cruise</div>
                <div className={classes.fieldValue}>
                  {currentEvent?.event_cruise?.cruise_name}
                </div>
                {/* <Input value={currentEvent?.event_cruise?.cruise_name}/> */}
              </>
            )}
          </div>

          <div className={classes.field}>
            <div className={classes.label}>Dates</div>
            <div className={classes.fieldValue}>
              {moment(currentEvent?.start_date).format('YYYY.MM.DD')} - {moment(currentEvent?.end_date).format('YYYY.MM.DD')}
            </div>
            {/* <DatePicker.RangePicker
              value={[moment(currentEvent?.start_date), moment(currentEvent?.end_date)]}
              allowClear={false}
              style={{width: '100%'}}
            /> */}
          </div>

          <div className={classes.field}>
            <div className={classes.label}>Event Type</div>
            <div className={classes.fieldValue}>
              {currentEvent?.event_type}
            </div>
            {/* <Radio.Group value={currentEvent?.event_type} style={{display: 'flex', flexDirection: 'column'}}>
              <Radio value={'Corporate'}>Corporate</Radio>
              <Radio value={'Public'}>Public</Radio>
              <Radio value={'Private'}>Private</Radio>
            </Radio.Group> */}
          </div>

          <div className={classes.field}>
            <div className={classes.label}>Terms and Conditions</div>
            <div className={classes.fieldValue}>
              <div>
                <Checkbox
                  checked={currentEvent?.terms_accepted}
                  disabled={!currentEvent?.terms_accepted}
                />
                <span style={{marginLeft: '8px'}}>
                  {currentEvent?.terms_accepted ? 'Terms and Conditions are accepted' : 'Terms and Conditions are not accepted'}
                </span>
              </div>
              {currentEvent?.sign_date &&
                <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                  <div style={{fontSize: '12px', color: 'rgba(0,0,0,.65)'}}>
                    User IP: {currentEvent?.user_ip}
                  </div>
                  <div style={{fontSize: '12px', color: 'rgba(0,0,0,.65)', marginLeft: '40px'}}>
                    Date: {moment(currentEvent?.sign_date).format('DD.MM.YYYY HH:mm')}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.field}>
            <div className={classes.label}>User name</div>
            <div className={classes.fieldValue}>
              {currentEvent?.user_name}
            </div>
            {/* <Input value={currentEvent?.user_name}/> */}
          </div>

          <div className={classes.field}>
            <div className={classes.label}>User email</div>
            <div className={classes.fieldValue}>
              {currentEvent?.user_email}
            </div>
            {/* <Input value={currentEvent?.user_email}/> */}
          </div>

          <div className={classes.field}>
            <div className={classes.label}>Event Code</div>
            <div className={classes.fieldValue}>
              {currentEvent?.code}
            </div>
            {/* <Input value={currentEvent?.code}/> */}
          </div>

          <div className={classes.field}>
            <div className={classes.label}>Event Link</div>
            <div className={classes.fieldValue}>
              {getBaseUrl() + '/events/details/' + currentEvent?.code}
            </div>
            {/* <Input value={getBaseUrl() + '/events/details/' + currentEvent?.code}/> */}
          </div>

          <div className={classes.field}>
            <div className={classes.label}>About</div>
            <div className={classes.fieldValue}>
              {currentEvent?.description}
            </div>
            {/* <Input.TextArea value={currentEvent?.description}/> */}
          </div>
        </div>
      </div>

    {!!currentEvent?.photo_urls?.length &&
      <div className={`${classes.formBlock} ${classes.photoList}`}>
        {currentEvent?.photo_urls.map(url => (
          <img src={url} key={url} alt='' className={classes.photo}/>
        ))}
      </div>
    }
    </>
  )
}

export default EventDetailsPage
