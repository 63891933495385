import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { advertisingAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'
import { AdsWithOccupiedSlotsType, AdvertisementEditingType, AdvertisementType, AdvertisingFetchParamsType, AdvertisingLocationsType, OccupiedSlotType } from '../types/advertisingTypes'
import { PaginationRequestTypes } from '../types/appTypes'
import { CancelTokenSource } from 'axios'

export const defaultTargetDataPagination = {page: 1, size: 10 as 10}

interface InitialStateType {
  advertisements: AdvertisementType[]
  advertisements_total_count: number

  current_advertisement: AdvertisementEditingType | null
  target_data_pagination: PaginationRequestTypes
  target_data_total_count: number
  occupied_slots: OccupiedSlotType[]
}

const initialState: InitialStateType = {
  advertisements: [],
  advertisements_total_count: 0,

  current_advertisement: null,
  target_data_pagination: defaultTargetDataPagination,
  target_data_total_count: 0,
  occupied_slots: []
}

export const advertisingSlice = createSlice({
  name: 'advertising',
  initialState,
  reducers: {
    setAdvertisements: (state, action: PayloadAction<AdvertisementType[]>) => {state.advertisements = action.payload},
    setCurrentAdvertisement: (state, action: PayloadAction<AdvertisementEditingType>) => {state.current_advertisement = action.payload},
    setTargetDataPagination: (state, action: PayloadAction<PaginationRequestTypes>) => {state.target_data_pagination = action.payload},
    setTargetDataTotalCount: (state, action: PayloadAction<number>) => {state.target_data_total_count = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllAdvertisementsThunk.fulfilled, (state, action) => {
        state.advertisements = action.payload.advertisements
        state.advertisements_total_count = action.payload.total_count
      })
      .addCase(GetAdvertisementByIdThunk.fulfilled, (state, action) => {
        state.current_advertisement = action.payload
      })
      .addCase(CreateAdvertisementThunk.fulfilled, (state, action) => {
        state.advertisements = [...state.advertisements, action.payload]
        state.advertisements_total_count = state.advertisements_total_count + 1
      })
      .addCase(EditAdvertisementThunk.fulfilled, (state, action) => {
        state.advertisements = state.advertisements.map(advertisement => {
          return advertisement.advertising_id === action.payload.advertising_id ? action.payload : advertisement
        })
      })
      .addCase(DeleteAdvertisementThunk.fulfilled, (state, action) => {
        state.advertisements = state.advertisements.filter(advertisement => advertisement.advertising_id !== action.payload)
        state.advertisements_total_count = state.advertisements_total_count - 1
      })
      .addCase(CheckOccupiedSlotsThunk.fulfilled, (state, action) => {
        state.occupied_slots = action.payload.map(ad => ad.occupied_ads_slots).flat()
      })
      .addCase(GetAdvertisementTargetDataThunk.fulfilled, (state, action) => {
        const hasDuplicate = (state.current_advertisement?.advertising_locations || []).some(loc => {
          return action.payload.advertising_locations.some(respLoc => loc.name === respLoc.name)
        });
        if (hasDuplicate) {
          return
        }
        state.target_data_total_count = action.payload.total_count
        state.current_advertisement = {
          ...state.current_advertisement!,
          advertising_locations: [
            ...(state?.current_advertisement?.advertising_locations || []),
            ...action.payload.advertising_locations
          ],
          ...(action.payload.advertising_locations[0]?.location_type === 'node'
            ? {
                nodes: [
                  ...(state?.current_advertisement?.nodes || []),
                  ...action.payload.advertising_locations.map(l => ({
                    code: l?.code!,
                    id: l?.node_id!,
                    label: l.node_type!,
                    latitude: l?.latitude!,
                    longitude: l?.longitude!,
                    name: l?.name!
                  }))
                ]
              }
            : {
              latitude_north: action.payload.advertising_locations[0]?.latitude_north,
              latitude_south: action.payload.advertising_locations[0]?.latitude_south,
              longitude_east: action.payload.advertising_locations[0]?.longitude_east,
              longitude_west: action.payload.advertising_locations[0]?.longitude_west,
              radius_area: action.payload.advertising_locations[0]?.radius_area,
              latitude: action.payload.advertising_locations[0]?.latitude,
              longitude: action.payload.advertising_locations[0]?.longitude,
              address: action.payload.advertising_locations[0]?.address,
            }
          ),
        }
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.advertisements = []
        state.advertisements_total_count = 0
        state.current_advertisement = null
        state.occupied_slots = []
      })
  }
})

export const {
  setAdvertisements,
  setCurrentAdvertisement,
  setTargetDataPagination,
  setTargetDataTotalCount,
} = advertisingSlice.actions

export const selectAdvertisementList = (state: RootState): AdvertisementType[] => state.advertising.advertisements
export const selectAdvertisementsTotalCount = (state: RootState): number => state.advertising.advertisements_total_count
export const selectCurrentAdvertisement = (state: RootState): AdvertisementEditingType | null => state.advertising.current_advertisement
export const selectOccupiedSlots = (state: RootState): OccupiedSlotType[] => state.advertising.occupied_slots
export const selectTargetDataPagination = (state: RootState): PaginationRequestTypes => state.advertising.target_data_pagination
export const selectTargetDataTotalCount = (state: RootState): number => state.advertising.target_data_total_count

export const GetAllAdvertisementsThunk = createAsyncThunk<{advertisements: AdvertisementType[], total_count: number}, {fetchParams: AdvertisingFetchParamsType, source: any}, AsyncThunkConfig>(
  'advertising/getAllAdvertisements',
  async (requestData, thunkAPI) => {
    try {
      const { status, data } = await advertisingAPI.getAdvertisementsList(requestData.fetchParams, requestData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetAdvertisementByIdThunk = createAsyncThunk<AdvertisementEditingType, {id: number, withLocations: boolean, source?: CancelTokenSource}, AsyncThunkConfig>(
  'advertising/getAdvertisementById',
  async ({id, withLocations, source}, thunkAPI) => {
    try {
      const { status, data } = await advertisingAPI.getAdvertisementById(id, withLocations, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({
          ...data,
          allowed_user_location: data?.user_locations,
        }, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const CreateAdvertisementThunk = createAsyncThunk<AdvertisementType, AdvertisementType, AsyncThunkConfig> (
  'advertising/createAdvertisement',
  async (advertisementData, thunkAPI) => {
    try {
      const { status, data } = await advertisingAPI.createAdvertisement(advertisementData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Advertisement has been added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditAdvertisementThunk = createAsyncThunk<AdvertisementType, {id: number, updatedData: AdvertisementType}, AsyncThunkConfig> (
  'advertising/editAdvertisement',
  async ({id, updatedData}, thunkAPI) => {
    try {
      const { status, data } = await advertisingAPI.editAdvertisement(id, updatedData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Advertisement has been edited'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteAdvertisementThunk = createAsyncThunk<number, number, AsyncThunkConfig> (
  'advertising/deleteAdvertisement',
  async (advertisementId, thunkAPI) => {
    try {
      const { status, data } = await advertisingAPI.deleteAdvertisement(advertisementId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(advertisementId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CheckOccupiedSlotsThunk = createAsyncThunk<AdsWithOccupiedSlotsType[], any, AsyncThunkConfig> (
  'advertising/checkOccupiedSlots',
  async (advertisementData, thunkAPI) => {
    try {
      const { status, data } = await advertisingAPI.checkOccupiedSlots(advertisementData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.advertising_locations, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetAdvertisementTargetDataThunk = createAsyncThunk<{advertising_locations: AdvertisingLocationsType[], total_count: number}, {id: number, pagination: PaginationRequestTypes, source?: CancelTokenSource}, AsyncThunkConfig> (
  'advertising/getAdvertisementTargetData',
  async ({id, pagination, source}, thunkAPI) => {
    try {
      const { status, data } = await advertisingAPI.getAdvertisementTargetData(id, pagination, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default advertisingSlice.reducer
 