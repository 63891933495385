import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { eventAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'
import { EventListFetchParamsType, EventType } from '../types/eventTypes'

const eventListFetchParamsDefaultValue: EventListFetchParamsType = {
  pagination: {page: 1, size: 10 as 10},
  filter: {
    search_value: '',
    event_kind: null,
    event_type: null
  }
}

interface InitialStateType {
  eventList: null | EventType[]
  eventListTotalCount: number
  eventListFetchParams: EventListFetchParamsType
  currentEvent: null | EventType
}

const initialState: InitialStateType = {
  eventList: null,
  eventListTotalCount: 0,
  eventListFetchParams: eventListFetchParamsDefaultValue,
  currentEvent: null
}

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEventList: (state, action: PayloadAction<null | EventType[]>) => {state.eventList = action.payload},
    setEventListTotalCount: (state, action: PayloadAction<number>) => {state.eventListTotalCount = action.payload},
    setEventListFetchParams: (state, action: PayloadAction<EventListFetchParamsType>) => {state.eventListFetchParams = action.payload},
    setCurrentEvent: (state, action: PayloadAction<null | EventType>) => {state.currentEvent = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetEventListThunk.fulfilled, (state, action) => {
        state.eventList = action.payload.events
        state.eventListTotalCount = action.payload.total_count
      })
      .addCase(GetEventByCodeThunk.fulfilled, (state, action) => {
        state.currentEvent = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.eventList = null
        state.eventListTotalCount = 0
        state.eventListFetchParams = eventListFetchParamsDefaultValue
      })
  }
})

export const {
  setEventList,
  setEventListTotalCount,
  setEventListFetchParams,
  setCurrentEvent,
} = eventSlice.actions

export const selectEventList = (state: RootState): null | EventType[] => state.event.eventList
export const selectEventListTotalCount = (state: RootState): number => state.event.eventListTotalCount
export const selectEventListFetchParams = (state: RootState): EventListFetchParamsType => state.event.eventListFetchParams
export const selectCurrentEvent = (state: RootState): null | EventType => state.event.currentEvent

export const GetEventListThunk = createAsyncThunk<{events: EventType[], total_count: number}, {fetchParams: EventListFetchParamsType, source: any}, AsyncThunkConfig>(
  'event/getEventList',
  async ({fetchParams, source}, thunkAPI) => {
    try {
      const formData = new FormData()
      !!fetchParams?.pagination && (formData.append('pagination', new Blob([JSON.stringify(fetchParams.pagination, null, 2)], {type: 'application/json'})))
      formData.append('filter', new Blob([JSON.stringify(fetchParams.filter, null, 2)], {type: 'application/json'}))
      const { status, data } = await eventAPI.getEventList(formData, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetEventByCodeThunk = createAsyncThunk<EventType, number, AsyncThunkConfig>(
  'event/getEventByCode',
  async (eventCode, thunkAPI) => {
    try {
      const { status, data } = await eventAPI.getEventByCode(eventCode)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default eventSlice.reducer
 