import { Checkbox, Form, FormInstance, Select } from 'antd'
import axios from 'axios'
import { sortBy } from 'lodash'
import { useEffect, useState } from 'react'
import commonClasses from './../ConnectionField.module.css'
import { ReactComponent as SearchIcon } from './../../../../img/icons/search.svg'
import { AdvertisementEditingType } from '../../../../types/advertisingTypes'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetEventListThunk, selectEventList, setEventList } from '../../../../store/eventReducer'

const ConnectionToEvent: React.FC<ConnectionToEventPropTypes> = ({
  form,
  isEditing,
  currentEditingData,
  disabled,
  handleNodeSelect
}) => {
  const dispatch = useAppDispatch()
  const events = useAppSelector(selectEventList)

  const [isLoading, setIsLoading] = useState(false)
  const [eventCode, setEventCode] = useState('')
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [defaultValueChanged, setDefaultValueChanged] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if(!!eventCode?.length) {
      dispatch(setEventList(null))
      setIsLoading(true)
      dispatch(GetEventListThunk({
        fetchParams: {
          filter: {
            search_value: eventCode,
            event_type: null,
            event_kind: null
          },
        },
        source
      }))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsLoading(false)
        }
      })
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, eventCode])

  useEffect(() => {
    form.setFieldsValue({node_id_list: []})
    return () => {
      form.setFieldsValue({node_id_list: []})
      setEventCode('')
      dispatch(setEventList(null))
    }
    // eslint-disable-next-line
  }, [form])

  const onEventSelect = (eventId: number) => {
    handleNodeSelect && handleNodeSelect(eventId, () => true)
    form.setFieldsValue({node_id_list: [eventId]})
    setDefaultValueChanged(true)
    setEventCode('')
  }

  return (
    <>
      <div className={`${commonClasses.blockTitle} ${commonClasses.advertisement}`}>
        Select the event of according to the event searched by the user:
      </div>
      <Form.Item>
        <Select
          placeholder='Enter the event code or name'
          style={{ width: '65%' }}
          suffixIcon={<SearchIcon style={{marginRight: '10px', marginBottom: '3px'}}/>}
          value={
            isEditing && !defaultValueChanged
              ? {value: currentEditingData?.advertising_locations?.[0]?.node_id!, label: `${currentEditingData?.advertising_locations?.[0]?.code} - ${currentEditingData?.advertising_locations?.[0]?.name}`}
              : form.getFieldValue('node_id_list')
          }
          showSearch
          onSearch={(val) => {
            setEventCode(val)
          }}
          onSelect={(event:any) => onEventSelect(event?.value)}
          filterOption={false}
          labelInValue
          open={isOptionsOpen}
          onDropdownVisibleChange={(isOpen => setIsOptionsOpen(isLoading || isOpen))}
          notFoundContent={isLoading
            ? 'Loading...'
            : events === null ? 'Start typing event code or name' : 'No event options found'}
          disabled={disabled}
          searchValue={eventCode}
        >
          {sortBy(events, event => event.name).map(event => (
            <Select.Option value={event.id} key={event.id}>
              {event.code} - {event.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={'is_special_offer'} valuePropName='checked'>
        <Checkbox disabled={disabled}>
          Special offers
        </Checkbox>
      </Form.Item>
    </>
  )
}

interface ConnectionToEventPropTypes {
  form: FormInstance
  isEditing?: boolean
  currentEditingData?: AdvertisementEditingType | null
  disabled?: boolean
  handleNodeSelect?: (id: number, removeCopiedNodes?: (nodeTypes?: string[]) => boolean) => void
}

export default ConnectionToEvent
